import { Injectable } from '@angular/core';
import {
  Auth,
  deleteUser,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  User,
} from '@angular/fire/auth';
import {
  BehaviorSubject,
  filter,
  from,
  Observable,
  switchMap,
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userIsLoggedIn: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);

  constructor(
    private auth: Auth,
  ) {
    this.auth.onAuthStateChanged(user => {
      if (user) {
        this.userIsLoggedIn.next(true);
        return;
      }
      this.userIsLoggedIn.next(false);
    });

   }

  delete(): Promise<void> {
    return deleteUser(this.auth.currentUser!);
  }

  async register(email: string, password: string) {
    try {
      const userCredential = await createUserWithEmailAndPassword(this.auth, email, password);
      return userCredential;
    } catch (e) {
      return null;
    }
  }

  async sendEmailVerification(): Promise<void> {
    return sendEmailVerification(this.auth.currentUser!);
  }

  async login({email, password} : {email: string, password: string}) {
    try {
      const user = await signInWithEmailAndPassword(this.auth, email, password);
      return user;
    } catch (e) {
      return null;
    }
  }

  logout(): Promise<void> {
    return signOut(this.auth);
  }

  currentUser(): Observable<User | null> {
    return this.userIsLoggedIn.asObservable().pipe(
    filter( isUserLoggedIn => isUserLoggedIn !== null ),
    switchMap(isUserLoggedIn => {
      if (isUserLoggedIn) {
        const currentUser = this.auth.currentUser as User;
        return from(Promise.resolve(currentUser));
      }
      return from(Promise.resolve(null));
    }));
  }

  getAccessToken(): Observable<string> {
    return this.userIsLoggedIn.asObservable().pipe(
      filter(isUserLoggedIn => isUserLoggedIn !== null),
      switchMap(isUserLoggedIn => {
        if (isUserLoggedIn) {
          const currentUser = this.auth.currentUser as User;
          return from(currentUser.getIdToken());
        } else {
          return from(Promise.resolve(''));
        }
    }));
  }

  async recoverPassword(email: string): Promise<void> {
    await sendPasswordResetEmail(this.auth, email);
  }
}
